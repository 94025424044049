import React from "react";
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

import uslug from "../../sections/three/images/uslug.svg";

class Sliders extends React.Component {
  render() {
    var settings = {
      responsive: [
        {
          breakpoint: 2048,
          settings: "unslick",
        },
        {
          breakpoint: 700,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <Slider {...settings} className="wrap-block">
        <div className="block">
          <div className="description">
            <div className="icon">
              <img src={uslug} alt="" />
            </div>
            <div className="title">Экспресс-отчёт</div>
            <ul className="list custom">
              <li>Бесплатная оценка за 1 минуту</li>
              <li>Минимум данных для расчета</li>
              <li>Точность оценки до 97%</li>
            </ul>
          </div>
          <div className="button yellow"><a href="/calculation">Оставить заявку</a></div>
        </div>
        <div className="block">
          <div className="description">
            <div className="icon">
              <img src={uslug} alt="" />
            </div>
            <div className="title">Отчет для ипотеки</div>
            <ul className="list custom">
              <li>Аккредитованные оценщики</li>
              <li>Подготовка отчета за 1 день</li>
              <li>Электронный и бумажный документ</li>
            </ul>
          </div>
          <div className="button yellow"><a href="/calculation-ipoteka">Оставить заявку</a></div>
        </div>

        <div className="block">
          <div className="description">
            <div className="icon">
              <img src={uslug} alt="" />
            </div>
            <div className="title">Краткий отчет</div>
            <ul className="list custom">
              <li>Аналоги со всех сайтов</li>
              <li>Точность оценки до 100%</li>
              <li>Отчет-презентация для клиента</li>
            </ul>
          </div>
          <div className="button yellow"><a href="/calculation-smart">Оставить заявку</a></div>
        </div>
      </Slider>
    );
  }
}

export default Sliders;
