import React from 'react';
import { connect } from 'react-redux';
import ReactDadataBox from 'react-dadata-box';
import Dropdown from 'react-dropdown';
import InputMask from 'react-input-mask';
import Cookies from 'js-cookie';
import store from '../redux/';

import './style.scss';
import 'react-dropdown/style.css';
import '../sections/one/style.scss';
import 'react-dadata/dist/react-dadata.css';

import logo from '../sections/one/images/logo.svg';
import one from './images/1.svg';
import two from './images/2.svg';
import three from './images/3.svg';

import Estimation from '../components/estimation';
// import { threadId } from 'worker_threads';
// import { response } from 'express';

const TOKEN_COOKIE = 'Token';

class CalculationIpoteka extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exit: 'Авторизуйтесь',
      check: '',
      listHome: [
        {
          label: '',
          value: '',
        },
      ],
      listHomeValue: 'Тип',
      listObject: [
        {
          label: '',
          value: '',
        },
      ],
      listObjectValue: 'Материал',

      address: {
        city: 'string',
        street: 'string',
        home: 'string',
        housing: 'string',
        flat: 'string',
        id: 'string',
      },

      region: '',
      floor: 0,
      homeType: 0,
      objectType: 0,
      buildYear: 'string',
      totalSpace: 'string',
      livingSpace: 'string',
      kitchenSpace: 'string',
      numberOfFloor: 0,
      numberOfRooms: 0,
      distanceToMetro: 0,
      fiasId: '',

      fullAddres: '',
      allTotalPrice: '',
      isOpenModalRegistration: false,

      step: 'request',
      disabled: false,
      requestId: '',
      phone: '',
    };

    this.getAction = this.getAction.bind(this);
    this.calculate = this.calculate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setSuggestion = this.setSuggestion.bind(this);
  }

  async componentDidMount() {
    const listHome = (await this.getTypeHomeApi()).objectTypes;
    const listObject = (await this.getTypeObjectApi()).houseTypes;

    this.setState({
      listHome: listHome.map((a) => ({ label: a.name, value: a.id })),
      listObject: listObject.map((a) => ({ label: a.name, value: a.id })),
    });
  }

  getAction() {
    if (this.props.token) return this.calculate;
    if (this.state.step === 'request') return this.handleSubmit;
    return this.handleConfirm;
  }

  handleChange(name) {
    return (event) => {
      this.setState({ [name]: event.target.value });
    };
  }

  setSuggestion = (data) => {
    this.setState({
      address: {
        city: data.data.city,
        street: data.data.street,
        home: data.data.house,
        flat: data.data.flat,
        housing: data.data.housing || '',
        id: data.data.house_fias_id || '',
      },
      region: data.data.region,
      fullAddres: data.value,
      fiasId: data.data.fias_id,
    });
    this.getTypeValidatesApi();
  };

  calculate = (e) => {
    if (e) e.preventDefault();
    const { listHomeValue } = this.state;
    const { listObjectValue } = this.state;
    const idHome = listHomeValue.value;
    const idObject = listObjectValue.value;

    const token = this.props.token;
    const floor = Number(this.state.floor);
    const address = this.state.address;
    const buildYear = String(this.state.buildYear);
    const totalSpace = this.state.totalSpace;
    const livingSpace = this.state.livingSpace;
    const kitchenSpace = this.state.kitchenSpace;
    const numberOfFloor = Number(this.state.numberOfFloor);
    const numberOfRooms = Number(this.state.numberOfRooms);
    const distanceToMetro = Number(this.state.distanceToMetro);

    fetch(`/api/express/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-token': token,
      },
      body: JSON.stringify({
        objectType: idObject,
        homeType: idHome,
        floor,
        address,
        buildYear,
        totalSpace,
        livingSpace,
        kitchenSpace,
        numberOfFloor,
        numberOfRooms,
        distanceToMetro,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        const status = await response.status;
        console.log(status);

        if (status === 403) {
          alert('Бесплатные "Экспресс-оценки" закончились');
        }
        if (status === 200) {
          this.setState({
            allTotalPrice: data.price,
            isOpenModalRegistration: true,
          });
        }
      })
      .catch(function (error) {});
  };

  getTypeHomeApi = async () => {
    const response = await fetch(`/default/object_types`, { method: 'GET' });
    const data = await response.json();
    return data;
  };
  getTypeObjectApi = async () => {
    const response = await fetch(`/default/home_types`, { method: 'GET' });
    const data = await response.json();
    return data;
  };
  getTypeValidatesApi = async () => {
    const response = await fetch(`/default/express-estimation-built-year`, {
      method: 'POST',
      body: JSON.stringify({
        fiasId: this.state.fiasId,
      }),
    });
    const data = await response.json();
    this.setState({
      buildYear: data.built_year,
      numberOfFloor: data.numberOfFloor,
      objectType: data.objectType,
      homeType: data.homeType,
      totalSpace: data.totalSpace,
      livingSpace: data.livingSpace,
      kitchenSpace: data.kitchenSpace,
      numberOfRooms: data.numberOfRooms,
      floor: data.floor,
    });
    return data;
  };

  onChangeSelectHome = (value) => {
    this.setState({ listHomeValue: value });
  };
  onChangeSelectObject = (value) => {
    this.setState({ listObjectValue: value });
  };
  toogleModalRegistration = () => {
    this.setState((state) => ({
      isOpenModalRegistration: !state.isOpenModalRegistration,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const phone = this.state.phone;
    this.setState({
      success: true,
    });
    fetch(`/api/register/send-code`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone }),
    })
      .then(async (response) => {
        const data = await response.json();
        this.setState({
          requestId: data.requestId,
        });
      })
      .catch(function (error) {});

    this.setState({
      step: 'confirm',
    });
  };

  handleConfirm = async (e) => {
    e.preventDefault();
    const code = this.state.phone;
    const requestId = this.state.requestId;
    fetch(`/api/register/confirm`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        requestId,
        role: `USER_ROLE_REALTOR`,
      }),
    })
      .then(async (response) => {
        const data = await response.json();
        const status = await response.status;
        console.log(status);
        console.log(response);
        if (status === 400) {
          this.setState({
            placeholder: data.details.code,
            phone: '',
            check: 'check',
          });
        }
        if (status === 500) {
          this.setState({
            placeholder: 'Не правильный код',
            phone: '',
            check: 'check',
          });
        }

        Cookies.set(TOKEN_COOKIE, data.token);
        Cookies.set('Phone', this.state.phone);
        store.dispatch({ type: 'auth', response: { token: data.token } });
        this.setState({ disabled: true });
        this.calculate();
      })
      .catch((error) => {});
  };

  renderLastStep() {
    const isToken = Boolean(this.props.token);
    const isRequestStep = this.state.step === 'request';

    const condition = isToken || isRequestStep;

    const description = condition
      ? 'Укажите номер Вашего мобильного телефона для того, чтобы авторизироваться на сервисе для дальнейшей оценки'
      : 'Введите код';

    const inputValue = condition ? 'phone' : 'code';
    const inputMask = condition ? '+79999999999' : '999999';
    const placeholder = condition ? '+7 (' : 'Введите код из смс';

    return (
      <form onSubmit={this.getAction()}>
        <div className="form">
          <p>
            Отчёт об оценке недвижимости полностью соответствует стандартам, установленным на территории РФ. Он включает в себя весь необходимый комплект документов. Отчет можно оформить онлайн - быстро и безопасно
          </p>
          <div className="line-wrap">
            <div className="line-input">
              <div className="icon">
                <img src={one} alt="" />
              </div>
              <div className="title">Введите полный адрес</div>
              <ReactDadataBox
                token="fb01e2f015c61a81d2905daa689967456fb706c6"
                onChange={this.setSuggestion}
                placeholder="Адрес"
                required
              />
              <input
                className="percent"
                type="number"
                placeholder="До метро"
                onChange={this.handleChange('distanceToMetro')}
              />
            </div>
            <div className="line-input">
              <div className="icon">
                <img src={two} alt="" />
              </div>
              <div className="title">Площадь м²</div>
              <div className="grid -three">
                <input
                  type="number"
                  placeholder="Общая"
                  onChange={this.handleChange('totalSpace')}
                  required
                  value={this.state.totalSpace}
                />
                <input
                  type="number"
                  placeholder="Жилая"
                  onChange={this.handleChange('livingSpace')}
                  required
                  value={this.state.livingSpace}
                />
                <input
                  type="number"
                  placeholder="Кухня"
                  onChange={this.handleChange('kitchenSpace')}
                  required
                  value={this.state.kitchenSpace}
                />
              </div>
            </div>
            <div className="line-input">
              <div className="icon">
                <img src={three} alt="" />
              </div>
              <div className="title">Дополнительно</div>
              <input
                type="number"
                placeholder="Количество комнат"
                onChange={this.handleChange('numberOfRooms')}
                className="percent"
                required
                value={this.state.numberOfRooms || ''}
              />
              <div className="grid -three">
                <input
                  type="number"
                  placeholder="Этаж"
                  onChange={this.handleChange('floor')}
                  required
                  value={this.state.floor || ''}
                />
                <input
                  type="number"
                  placeholder="Этажей в доме"
                  onChange={this.handleChange('numberOfFloor')}
                  value={this.state.numberOfFloor || ''}
                  required
                />
                <input
                  type="number"
                  placeholder="Год постройки"
                  onChange={this.handleChange('buildYear')}
                  required
                  value={this.state.buildYear || ''}
                />
              </div>
              <div className="grid -two">
                <Dropdown
                  options={this.state.listHome}
                  onChange={this.onChangeSelectHome}
                  value={this.state.listHomeValue}
                  placeholder="Тип"
                  required
                />
                <Dropdown
                  options={this.state.listObject}
                  onChange={this.onChangeSelectObject}
                  value={this.state.listObjectValue}
                  placeholder="Материал"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form">
          <p>{description}</p>
          <div className="line-wrap">
            <div className={`line-input lastS ${this.state.check}`}>
              <div className="icon">
                <img src={one} alt="" />
              </div>
              {!this.props.token && (
                <InputMask
                  key={inputValue}
                  mask={inputMask}
                  className={inputValue}
                  type="text"
                  placeholder={placeholder}
                  value={this.state[inputValue]}
                  onChange={this.handleChange('phone')}
                  required
                  disabled={this.state.disabled}
                />
              )}
              <button className="button yellow">Получить отчет для ипотеки</button>
            </div>
          </div>
        </div>
      </form>
    );
  }
  handeleExit = () => {
    Cookies.remove(TOKEN_COOKIE);
    store.dispatch({ type: 'logout' });
    window.location.reload();
  };
  onClickLogin = () => {
    const { token } = this.props;
    if (token) {
      window.open(
        `https://domprice.pro/auth/realtor-token?token=${token}`
      );
    } else {
      this.toogleModalRegistration();
    }
  };
  render() {
    return (
      <React.Fragment>
        <section className="calculation">
          <div className="container">
            <div className="menu-block">
              <div className="container">
                <div className="left-block">
                  <a href="/" className="logo">
                    <img src={logo} alt="" />
                  </a>
                </div>
                <div className="right-block">
                  {/* <a href="mailto:domprice@ya.ru" className="question">
                    Задать вопрос
                  </a> */}
                  {this.props.token && (
                    <div className="block-button">
                      <div className="enter" onClick={this.onClickLogin}>
                        Личный кабинет
                      </div>
                      <div className="enter" onClick={this.handeleExit}>
                        Выйти
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="wrap-block">
              <div className="title">Получить отчет для ипотеки</div>
              {this.renderLastStep()}
            </div>
          </div>
        </section>
        <div className="footer">
          <div className="container">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <a href="mailto:domprice@ya.ru">domprice@ya.ru</a>
          </div>
        </div>
        {this.state.isOpenModalRegistration && (
          <Estimation
            onClose={this.toogleModalRegistration}
            price={this.state.allTotalPrice}
            addres={this.state.fullAddres}
            area={this.state.totalSpace}
            region={this.state.region}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapState(state) {
  return {
    token: state.token,
  };
}

export default connect(mapState)(CalculationIpoteka);
