import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import App from "./App";
import Calculation from "./page/calculation";
import CalculationIpoteka from "./page/calculation-ipoteka";
import CalculationSmart from "./page/calculation-smart";

import { Provider } from "react-redux";
import store from "./redux";

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={App} />
        <Route exact path="/calculation" component={Calculation} />
        <Route exact path="/calculation-ipoteka" component={CalculationIpoteka} />
        <Route exact path="/calculation-smart" component={CalculationSmart} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("root")
);
